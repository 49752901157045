import React, { useState, useEffect } from "react"
import { Navbar, Nav, Button } from 'react-bootstrap'
import { GoogleLogout } from 'react-google-login';
import { Link } from 'react-router-dom'

export default function Header(props) {
    
    return (
        <Navbar bg="light" variant="light">
            <Navbar.Brand to="/">Flexicar Captación</Navbar.Brand>
            <Nav className="mr-auto">
                <Link className="nav-link" to="/">Llamadas</Link>
                <Link className="nav-link" to="/agentes">Agentes</Link>
                
            </Nav>

            <Link to="/nuevo-registro"><Button variant="primary">Nuevo Registro</Button></Link>
            {props.logged.perfil === 0 ? 
                <Link to="/nuevo-usuario"><Button className="admin">Nuevo usuario</Button></Link>
                : null
            }
            <GoogleLogout
                clientId="294233223970-m4192rk4b5j1liu61b1s19ui9fs3q41s.apps.googleusercontent.com"
                buttonText="Logout"
                onLogoutSuccess={() => {props.googleOut()}}
            >
            </GoogleLogout>
           
        </Navbar>
    );
}