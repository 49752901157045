import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";
import { Container, Row, Col, Table, Button, Form, FormControl, Pagination } from 'react-bootstrap'
import axios from "axios";
import { Link } from 'react-router-dom'

export default function Llamadas(props) {
    const [call, setCall] = useState();
    const [busqueda, setBusqueda] = useState({ resultado: "" });
    const [pagination, setPagination] = useState({ start: 0, end: 20, page: 1 })
    const [estado, setEstado] = useState(["adquirido", "rechazado", "pendiente", "vendidocliente", "noasignado"])
    const [total, setTotal] = useState(0)
    const id = props.logged.id
    
     const loadCalls = async () => {
        const response = await axios.get(`/api/calls?id=${id}&rol=${props.logged.perfil}&estado=${[estado]}&start=${pagination.start}&end=${pagination.end}`);
         setCall(response.data.llamadas)
         setTotal(response.data.total)
    }
    useEffect(() => {
        loadCalls();
    }, []);

    useEffect(() => {
        if (call){
        loadCalls()
            setPagination({ start: 0, end: 20, page:1})
        }
    }, [estado]);

    useEffect(() => {
        if (call) {
            loadCalls();
        }
    }, [pagination]);

    const handleInputChange = (event) => {
        setBusqueda({
            ...busqueda,
            [event.target.name]: event.target.value
        })
    }
    const enviarResultado = async (event) => {
        event.preventDefault()
        const redirect = (() => props.history.push('/nuevo-registro', { busqueda: busqueda.resultado}))
        const response = await axios.post('/api/searchCalls', { busqueda: busqueda.resultado });
        setCall(response.data)
       if (response.data.length === 0) {
           redirect()
        }
    }

    return (

        <section className="tabla">
       
            <Container>
                
                <Row className="justify-content-center filtro">
                    <Form onSubmit={enviarResultado} inline>
                        Introduzca la matrícula o el teléfono:
                        <FormControl type="text" className="mr-sm-2" value={busqueda.resultado} onChange={handleInputChange} name="resultado" />
                        <button type="submit" className="btn btn-primary">Buscar</button>
                    </Form>
                </Row>
                    
                <Row className="justify-content-center filtro">
                    <Button className="rechazado" onClick={() => setEstado(["rechazado"])}>No molestar</Button>
                    <Button className="adquirido" onClick={() => setEstado(["adquirido"])}>Finalizadas</Button>
                    <Button className="vendidocliente" onClick={() => setEstado(["vendidocliente"])}> Vendido cliente</Button>
                    <Button className="pendiente" onClick={() => setEstado(["pendiente"])}>Pendientes</Button>
                    <Button className="noasignado" onClick={() => setEstado(["noasignado"])}>No asignado</Button>
                    <Button className="todos" onClick={() => setEstado(["adquirido", "rechazado", "pendiente", "vendidocliente", "noasignado"])}>Mostrar Todos</Button>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col>
                        <Table bordered>
                            <thead>
                                <tr>
                                    <th>Nombre</th>
                                    <th>Marca</th>
                                    <th>Modelo</th>
                                    <th>Teléfono</th>
                                    <th>Última llamada</th>
                                    <th>Nota</th>
                                </tr>
                            </thead>

                            <tbody>
                                
                                {call && call.map(elm => (
                                    <tr key={elm.llamadas_id} className={elm.estado}>
                                        <td className={elm.estado}><Link to={`/llamada/${elm.llamadas_id}`} className="link-tab">{elm.nombre}</Link></td>
                                        <td><Link to={`/llamada/${elm.llamadas_id}`} className="link-tab">{elm.marca}</Link></td>
                                        <td><Link to={`/llamada/${elm.llamadas_id}`} className="link-tab">{elm.modelo}</Link></td>
                                        <td><Link to={`/llamada/${elm.llamadas_id}`} className="link-tab">{elm.telefono}</Link></td>
                                        <td><Link to={`/llamada/${elm.llamadas_id}`} className="link-tab">{elm.ultima_llamada}</Link></td>
                                        <td><Link to={`/llamada/${elm.llamadas_id}`} className="link-tab">{elm.nota}</Link></td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                {total >= 20 ?
                    <Pagination className="justify-content-center">
                        {pagination.start !== 0 ?
                            <>
                                <Pagination.First onClick={() => setPagination({ start: 0, end: 20, page: 1 })} > Volver a inicio</Pagination.First>

                                <Pagination.Prev onClick={() => setPagination({ start: (pagination.start - 20), end: (pagination.end ) })} >Anterior</Pagination.Prev>
                            </>
                            : null}
                        <Pagination.Item disabled>Página {pagination.page}</Pagination.Item>
                        {call.length === 20 ?
                            <>
                                <Pagination.Next onClick={() => setPagination({ start: (pagination.start + 20), end: (pagination.end), page:(pagination.page +1)})}>Siguiente</Pagination.Next>
                                
                            </>
                            : null}
                    </Pagination> : null}
               
            </Container>

        </section>
    );
}
