import { Toast } from 'react-bootstrap'


const Alert = ({ show, toastHeader, toastText, handleToast }) => {
    return (
        <Toast show={show} className={toastHeader} onClose={() => handleToast(false)} delay={3000} autohide style={{ position: 'fixed', top: 30, right: 10, width: 500 }}>
            <Toast.Header>
                <strong className="mr-auto">{toastHeader}</strong>
            </Toast.Header>
            <Toast.Body>{toastText}</Toast.Body>
        </Toast>
    )
}

export default Alert