import { Container, Row, Col } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";
import Logo from "./flexicarlogo.png"
import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from 'react-router-dom'
import axios from "axios";

import { GoogleLogin, GoogleLogout } from 'react-google-login';
import Cookies from "universal-cookie";

import Header from "./layout/Header"
import Llamadas from "./Lista-llamadas"
import NuevoRegistro from "./Form-llamada"
import Agentes from "./Lista-agentes"
import Agente from "./Form-agente"
import Alert from '../components/shared/Toast'


export default function App(props) {
  const cookies = new Cookies();
  const [log, setLog] = useState();
  const [access, setAccess] = useState({ email: 'noaccess', id: '', perfil: '' })
  const [token, setToken] = useState();
  const [type, setType] = useState(null)
  const [toast, setToast] = useState({ showToast: false,  toastText: 'Registrado Correctamente', toastHeader:'Enviado' });
  

  useEffect(() => {
    if (cookies.get("token")) {
      checkToken(cookies.get("token"));
    }
    
  }, []);

  const checkToken = async (token2) => {
    const response = await axios.post(
      "https://www.googleapis.com/oauth2/v1/tokeninfo?access_token=" + token2
    );
    if (response?.data.expires_in > 0) {
      const us = response.data.email;
  
      setLog(us);
      
    }
   
  };

  const responseGoogleIn = (response) => {
  
    if (response.accessToken) {
      setLog(response.profileObj.email);
      setToken(response.accessToken)
      cookies.set("token", response.accessToken, { path: "/" });
 
    }
   
  };
  useEffect(() => {
    getAccess()
    
  }, [log]);
  useEffect(() => {
    getAccessType()

  }, [access]);

  const getAccess = async () => {
   
    const response = await axios.get(`/api/access?email=${log}&token=${token}`);
    if (response.data[0]){
      setAccess({ email: response.data[0].email, id: response.data[0].usuario_id, perfil: response.data[0].perfil})
      
   }
   else {
     console.log("sin acceso")
    }
    
  }
  const responseGoogleOut = () => {
    setLog();
    setAccess({ email: 'noaccess', id: '', perfil: '' });
    setToken()
    setType(null)
    cookies.set("token", { path: "/" });
  };
  
  const getAccessType = () => {
    if (log && access.email==='noaccess') {
     setType('noaccess')
    }
    if (log && access.email !== 'noaccess') {
      setType('access')
      
    }
    
  }
  const handleToast = (visible, text, header) => setToast({ showToast: visible, toastText: text, toastHeader: header})
  

  return (
    <>
      
      

      {type === "noaccess" ?
        <section className="login" style={{ paddingTop: "20vh" }}>
          <figure className="justify-content-center">
            <img src='https://captacion.flexicar.es/img/flexicarlogo.png' alt="logo flexicar" />
          </figure>
          <h1>No tienes acceso a esta herramienta</h1>
        </section> : null}
      {type === "access" ?
      <div>
              <Header googleOut={responseGoogleOut} logged={access}  />
              <main>
                <Switch>
                  <Route path='/' exact render={props => <Llamadas logged={access} {...props} />} />
                </Switch>
                <Switch>
                  <Route path='/agentes' render={props => <Agentes logged={access} {...props} />} />
                </Switch>
                <Switch>
                  <Route path='/nuevo-registro' render={props => <NuevoRegistro logged={access} {...props} success={handleToast} />} />
                </Switch>
                <Switch>
              <Route path="/llamada/:llamada_id" render={props => <NuevoRegistro logged={access} {...props} success={handleToast} />} />
                </Switch>
                <Switch>
                  <Route path="/agente/:usuario_id" render={props => <Agente logged={access} success={handleToast} {...props} />} />
                </Switch>
                <Switch>
                  <Route path="/nuevo-usuario" render={props => <Agente logged={access} success={handleToast} {...props} />} />
                </Switch>
            <Alert show={toast.showToast} handleToast={handleToast} toastText={toast.toastText} toastHeader={toast.toastHeader} />
              </main>
            </div>
        : null}
      {type=== null ?
        <section className="login" style={{ paddingTop: "20vh" }}>
          <Container>
            <Row className="justify-content-center" >
              <Col md={{ span: 6 }}>
                <figure className="justify-content-center">
                  <img src={Logo} alt="logo flexicar" />
                </figure>
                <h1>Captación Flexicar</h1>
                <div className="justify-content-center">
        <GoogleLogin
                    clientId="294233223970-m4192rk4b5j1liu61b1s19ui9fs3q41s.apps.googleusercontent.com"
          buttonText="Iniciar sesión"
          onSuccess={responseGoogleIn}
          onFailure={responseGoogleIn}
          cookiePolicy={"single_host_origin"}
          //isSignedIn={true}
                  />
                </div>
              </Col>
            </Row>
          </Container>
      </section>
      :null
      }
    </>
  );
}


