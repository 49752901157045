import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";
import { Container, Row, Col, Table, Button } from 'react-bootstrap'
import axios from "axios";
import { Link } from 'react-router-dom'

export default function Agentes(props) {
    const [agent, setAgent] = useState([]);

    useEffect(() => {
        loadUsers();

    }, []);


    const loadUsers = async () => {
        const response = await axios.get('/api/users');
        setAgent(response.data)
    }

    return (
        <main>
            <section className="tabla">
                <Container>
                    <Link to="/"><Button>Volver a llamadas</Button></Link>
                    
                    <Row>
                        <Col>
                            <Table bordered>
                                <thead>
                                    <tr>
                                        <th>Agente</th>
                                        <th>Pendientes</th>
                                        <th>Finalizadas</th>
                                        <th>No Molestar</th>
                                        <th>Vendido por cliente</th>
                                        <th>No asignado</th>
                                        <th>Totales</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {agent.map(elm => (
                                        <tr key={elm.usuario_id}>
                                            <td><Link to={`/agente/${elm.usuario_id}`} className="link-tab">{elm.nombre}</Link></td>
                                            <td>{elm.pendiente}</td>
                                            <td>{elm.adquirido}</td>
                                            <td>{elm.rechazado}</td>
                                            <td>{elm.vendidocliente}</td>
                                            <td>{elm.noasignado}</td>
                                            <td>{elm.total}</td>

                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            </section>
        </main>
    );
}

