import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";
import { Container, Row, Col, Form } from 'react-bootstrap'
import axios from "axios";
import { Toast } from 'react-bootstrap'

export default function NuevoRegistro(props) {
    const [datos, setDatos] = useState({
        nombre: '',
        telefono: '',
        email: '',
        poblacion: '',
        marca: '',
        modelo: '',
        matricula: '',
        modificador: props.logged.id,
        anuncio: '',
        pactado: '',
        adelanto: '',
        comision: '',
        primeraLlamada: '',
        ultimaLlamada: '',
        creador: props.logged.id,
        comentarios: '',
        estado: 'pendiente',
        nota: '',
    });
    const [errors, setErrors] = useState({})
    const [duplicado, setDuplicado] = useState(false)
    const llamada_id = props.match.params.llamada_id
    const isAddMode = !llamada_id;
    useEffect(() => {
        if (!isAddMode) {
            getCall();
        }

    }, []);

    const getCall = async () => {
        const llamada_id = props.match.params.llamada_id
        const response = await axios.get(`/api/call/${llamada_id}`);
        setDatos(response.data[0])

    }
    const handleInputChange = (event) => {
        setDatos({
            ...datos,
            [event.target.name]: event.target.value
        })


    }
    const handleValidation = () => {

        const fields = datos

        let formIsValid
        if (fields["nombre"].length === 0) {
            formIsValid = false;

            props.success(true, 'El campo  nombre es obligatorio', 'Error')
        }

        else if (!String(fields["telefono"]).match(/^.{9,9}$/)) {
            formIsValid = false;

            props.success(true, 'El teléfono debe tener 9 dígitos', 'Error')
        }
        else if (fields["marca"].length === 0) {
            formIsValid = false;

            props.success(true, 'El campo  marca es obligatorio', 'Error')
        }
        else if (fields["modelo"].length === 0) {
            formIsValid = false;
            props.success(true, 'El campo  modelo es obligatorio', 'Error')
        }
        else {
            formIsValid = true
        }

        return formIsValid
    }
    const enviado = () => {
        props.success(true, 'Registrado correctamente', 'Enviado')
    }
    const editado = () => {
        props.success(true, 'Editado correctamente', 'Editado')
    }
    const notvalidated = () => {
        props.success(true, 'El registro tiene errores', 'Error')
    }
    const duplicar = async () => {
        setDatos({ ...datos, creador: props.logged.id, modificador: props.logged.id, estado: 'pendiente', creador_nombre: '' })

        setDuplicado(true)
    }
    const enviarDatos = async (event) => {

        if (handleValidation()) {
            //await event.preventDefault()

            const redirect = (() => props.history.push('/'))
            if (isAddMode) {
                const response = await axios.post('/api/newCall', { datos });
                setDatos(response)
                enviado()
                redirect()
            }
            if (duplicado) {
                const response = await axios.post('/api/newCall', { datos });
                setDatos(response)
                enviado()
                redirect()
            }
            else {
                const response = await axios.put('/api/editCall', { datos });
                setDatos(response)
                editado()
                redirect()
            }


        }
    }
    const notSearch = !props.location.state
    if (!notSearch) {

        const busqueda = props.location.state.busqueda.trim()
        if (busqueda.length === 9) {
            datos.telefono = busqueda
        }
        else {
            datos.matricula = busqueda
        }
    }
    console.log(datos)
    console.log(props.logged)
    return (

        <section className="form">

            {datos.creador === props.logged.id ?
                <Container>

                    <div>
                        <h3>Datos cliente</h3>
                        <Form.Row>
                            <Form.Group as={Col} controlId="name">
                                <Form.Label>Nombre*</Form.Label>
                                <Form.Control type="text" placeholder="Nombre" onChange={handleInputChange} name="nombre" value={datos.nombre} required />

                            </Form.Group>

                            <Form.Group as={Col} controlId="telefono">
                                <Form.Label>Teléfono *</Form.Label>
                                <Form.Control type="number" placeholder="Teléfono" onChange={handleInputChange} name="telefono" value={datos.telefono} required maxLength='9' />

                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="poblacion">
                                <Form.Label>Población</Form.Label>
                                <Form.Control type="text" placeholder="Población" onChange={handleInputChange} name="poblacion" value={datos.poblacion} />
                            </Form.Group>

                            <Form.Group as={Col} controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" placeholder="Email" onChange={handleInputChange} name="email" value={datos.email} />

                            </Form.Group>
                        </Form.Row>
                        <h3>Datos del vehículo</h3>
                        <Form.Row>
                            <Form.Group as={Col} controlId="marca">
                                <Form.Label>Marca*</Form.Label>
                                <Form.Control type="text" placeholder="Marca" onChange={handleInputChange} name="marca" value={datos.marca} required />
                            </Form.Group>

                            <Form.Group as={Col} controlId="modelo">
                                <Form.Label>Modelo*</Form.Label>
                                <Form.Control type="text" placeholder="Modelo" onChange={handleInputChange} name="modelo" value={datos.modelo} required />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="matricula">
                                <Form.Label>Matrícula</Form.Label>
                                <Form.Control type="text" placeholder="Matrícula" onChange={handleInputChange} name="matricula" value={datos.matricula} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="anuncio">
                                <Form.Label>Anuncio</Form.Label>
                                <Form.Control type="text" placeholder="Anuncio" onChange={handleInputChange} name="anuncio" value={datos.anuncio} />
                                <a href={datos.anuncio} target="_blank">Ir al anuncio</a>
                            </Form.Group>
                        </Form.Row>
                        <h3>Datos económicos</h3>
                        <Form.Row>
                            <Form.Group as={Col} controlId="pactado">
                                <Form.Label>Pactado</Form.Label>
                                <Form.Control type="text" placeholder="Pactado" onChange={handleInputChange} name="pactado" value={datos.pactado} />
                            </Form.Group>

                            <Form.Group as={Col} controlId="comision">
                                <Form.Label>Comisión</Form.Label>
                                <Form.Control type="text" placeholder="Comisión" onChange={handleInputChange} name="comision" value={datos.comision} pattern="[0-9]*" />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="adelanto">
                                <Form.Label>Adelanto</Form.Label>
                                <Form.Control type="text" placeholder="Adelanto" onChange={handleInputChange} name="adelanto" value={datos.adelanto} />
                            </Form.Group>
                        </Form.Row>
                        <h3>Datos internos</h3>
                        {!isAddMode ?
                            <>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="primeraLlamada">
                                        <Form.Label>Primera llamada:</Form.Label>
                                        <p>{datos.alta}</p>
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="ultimaLlamada">
                                        <Form.Label>Última llamada</Form.Label>
                                        <p>{datos.ultima_llamada}</p>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="creador">
                                        <p>Creador: {datos.creador_nombre}</p>
                                    </Form.Group>
                                </Form.Row>
                            </>
                            : null}
                        <Form.Group controlId="comentarios">
                            <Form.Label>Comentarios:</Form.Label>
                            <Form.Control as="textarea" rows={3} onChange={handleInputChange} name="comentarios" value={datos.comentarios} />
                        </Form.Group>
                        <Form.Row>
                            <Form.Group controlId="estado">
                                <Form.Label>Estado</Form.Label>
                                <Form.Control as="select" custom onChange={handleInputChange} name="estado">
                                    <option selected={datos.estado === 'pendiente' ? true : false} value={"pendiente"}>Pendiente</option>
                                    <option selected={datos.estado === 'adquirido' ? true : false} value={"adquirido"}>Adquirido</option>
                                    <option selected={datos.estado === 'rechazado' ? true : false} value={"rechazado"}>Rechazado</option>
                                    <option selected={datos.estado === 'libre' ? true : false} value={"libre"}> Libre</option>
                                    <option selected={datos.estado === 'vendidocliente' ? true : false} value={"vendidocliente"}>Vendido por cliente</option>
                                    <option selected={datos.estado === 'noasignado' ? true : false} value={"noasignado"}>No asignado</option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group as={Col} controlId="nota">
                                <Form.Label>Nota</Form.Label>
                                <Form.Control type="text" placeholder="Nota" onChange={handleInputChange} name="nota" value={datos.nota} />
                            </Form.Group>
                        </Form.Row>
                        <button onClick={() => enviarDatos()} className="btn btn-primary">Enviar</button>
                    </div>


                </Container>
                :
                <section className="agente">
                    <Container>
                        <h3>Datos Agente</h3>
                        <Row clasName="justify-content-center">
                            <Col md={{ span: 6 }} >
                                <p><strong>Nombre:</strong>  {datos.nombre}</p>
                                <p><strong>Teléfono:</strong> {datos.telefono}</p>
                            </Col>
                            <Col md={{ span: 6 }} >
                                <p><strong>Población:</strong> {datos.poblacion}</p>
                                <p><strong>Email:</strong> {datos.email}</p>
                            </Col>
                        </Row>
                        <h3>Datos Vehículo</h3>
                        <Row clasName="justify-content-center">
                            <Col md={{ span: 6 }} >
                                <p><strong>Marca:</strong> {datos.marca}</p>
                                <p><strong>Modelo:</strong> {datos.modelo}</p>
                            </Col>
                            <Col md={{ span: 6 }} >
                                <p><strong>Matricula:</strong> {datos.matricula}</p>
                                <p><strong>Anuncio:</strong> <a href={datos.anuncio}>Ir al anuncio</a></p>
                            </Col>
                        </Row>
                        <h3>Datos Económicos</h3>
                        <Row clasName="justify-content-center">
                            <Col md={{ span: 6 }} >
                                <p><strong>Pactado:</strong> {datos.pactado}</p>
                                <p><strong>Comisión:</strong> {datos.comision}</p>
                            </Col>
                            <Col md={{ span: 6 }} >
                                <p><strong>Adelantado:</strong> {datos.adelantado}</p>
                            </Col>
                        </Row>
                        <h3>Datos Internos</h3>
                        <Row clasName="justify-content-center">
                            <Col md={{ span: 6 }} >
                                <p><strong>Primera llamada:</strong> {datos.alta}</p>
                                <p><strong>Última llamada:</strong> {datos.ultima_llamada}</p>
                            </Col>
                            <Col md={{ span: 6 }} >
                                <p><strong>creador:</strong> {datos.creador_nombre}</p>
                            </Col>
                            <Col md={{ span: 12 }} >
                                <p><strong>Comentarios:</strong></p>
                                <p>{datos.comentarios}</p>
                            </Col>
                            <Col md={{ span: 6 }} >
                                <p><strong>Estado:</strong> {datos.estado}</p>
                                {!isAddMode && datos.estado === 'libre' ? <button onClick={duplicar} className="btn btn-primary">Duplicar</button> : null}
                            </Col>
                            <Col md={{ span: 6 }} >
                                <p><strong>Nota:</strong> {datos.nota}</p>
                            </Col>
                        </Row>
                    </Container>

                </section>
            }

        </section>
    );
}