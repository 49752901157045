import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";
import { Container, Row, Col, Form } from 'react-bootstrap'
import axios from "axios";

import Llamadas from './Lista-llamadas'
import { Link } from "react-router-dom";


export default function EditarUsuario(props) {
    const usuario_id = props.match.params.usuario_id
    const isAddMode = !usuario_id;
    const [loggedUser, setLoggedUser] = useState({
        rol: 'administrador'
    })
    const [nombre, setNombre] = useState()
    const [email, setEmail] = useState()
    const [telefono, setTelefono] = useState()
    const [tienda, setTienda] = useState()
    const [perfil, setPerfil] = useState(1)
    const [activo, setActivo] = useState(true)
    const [user, setUser] = useState({ id: usuario_id })
     const [errors, setErrors] = useState({})
    useEffect(() => {
        if (!isAddMode) {
            getUser();
        }
    }, []);
    const getUser = async () => {
        const usuario_id = props.match.params.usuario_id
        const response = await axios.get(`/api/user/${usuario_id}`);
        setNombre(response.data.nombre)
        setEmail(response.data.email)
        setTelefono(response.data.telefono)
        setTienda(response.data.tienda)
        setPerfil(response.data.perfil)
        setActivo(response.data.activo)
     
    }
    const handleValidation = () => {
        let errorText = errors;
        let formIsValid = true
        if (!nombre || !email || !tienda) {
            formIsValid = false;
        }
        if (email && !email.match(/^([a-z0-9_\.-]+)@flexicar\.es$/)) {
            formIsValid = false;
            notflexicaremail()
        }
       

        console.log(formIsValid)
        return formIsValid
    }
const enviado = () => {
        props.success(true, 'Registrado correctamente', 'Enviado')
    }
    const editado = () => {
        props.success(true, 'Editado correctamente', 'Editado')
    }
    const error = () => {
        props.success(true,'El usuario ya existe', 'Error')
    }
    const notflexicaremail = () => {
        props.success(true, 'El email debe pertenecer a flexicar', 'Error')
    }
    const notvalidated = () => {
       
        if (!email) {
            props.success(true, 'El email del agente es obligatorio', 'Error')
        }
        if (!tienda) {
            props.success(true,'La tienda es obligatoria', 'Error')
        }
        if (!nombre) {
            props.success(true, 'El nombre del agente es obligatorio', 'Error')
        }
    }
 
    const enviarDatos = async (event) => {
        event.preventDefault()
        const redirect = (() => props.history.push('/agentes'))
        if (handleValidation()) {
            if (isAddMode) {
                const data = { nombre, email, telefono, tienda, perfil: +perfil, activo }
                const response = await axios.post('/api/newUser', { data });
                if (response.data.userExist) {
                    error()
                }
                else {
                    enviado()
                    redirect()
                }
            
            }
            else {
                const datos = { nombre, email, telefono, tienda, perfil: +perfil, activo, usuario_id }
                console.log(datos)
                const response = await axios.put(`/api/editUser/${usuario_id}`, { datos });
                editado()
                redirect()
            }
        }
        else {
            notvalidated()
        }

    }

   
    return (
        <>

            {
                props.logged.perfil === 0 

                    ?

                    <section className="form" >
                        <Container>

                            <Form onSubmit={enviarDatos}>
                                <h3>Datos Agente</h3>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="nombre">
                                        <Form.Label>Nombre*</Form.Label>
                                        <Form.Control type="text" placeholder="Nombre" onChange={(event) => setNombre(event.target.value)} name="nombre" value={nombre} required />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="telefono">
                                        <Form.Label>Teléfono</Form.Label>
                                        <Form.Control type="text" placeholder="Teléfono" onChange={(event) => setTelefono(event.target.value)} name="telefono" value={telefono} />
                                        <span style={{ color: "red" }}>{errors["telefono"]}</span>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>

                                    <Form.Group as={Col} controlId="email">
                                        <Form.Label>Email*</Form.Label>
                                        <Form.Control type="email" placeholder="Email" onChange={(event) => setEmail(event.target.value)} name="email" value={email} required />
                                        <span style={{color: "red"}}>{errors["email"]}</span>
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="tienda">
                                        <Form.Label>Tienda*</Form.Label>
                                        <Form.Control type="text" placeholder="Tienda" onChange={(event) => setTienda(event.target.value)} name="tienda" value={tienda} required />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="estado">
                                        <Form.Label>Perfil*</Form.Label>
                                        <Form.Control as="select" custom onChange={(event) => setPerfil(event.target.value)} name="perfil">
                                            <option selected={perfil === 1 ? true : false} value={1}>Agente</option>
                                            <option selected={perfil === 0 ? true : false} value={0}>Administrador</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="estado">
                                        <Form.Check type="switch" id="custom-switch" label={activo ? "Usuario activo" : "Desactivar usuario"} name="activo" onChange={(event) => setActivo(!event.target.defaultChecked)} defaultChecked={activo} />
                                    </Form.Group>
                                </Form.Row>

                                <button type="submit" className="btn btn-primary">Enviar</button>
                                {!isAddMode ?
                                    <a href={`mailto:${email}`} className="btn btn-primary">Enviar Email </a>
                                    : null}
                            </Form>

                        </Container>
                        {
                            usuario_id !== '' && !isAddMode
                                ?
                                <div className="callsagent">
                                <Llamadas logged={user
                                } id={usuario_id} />
                                </div>
                                :
                                null
                        }

                    </section>


                    :
                    <section className="agente">
                        <Container>
                            <h3>Datos Agente</h3>
                            <Row clasName="justify-content-center">

                                <Col md={{ span: 6 }} >
                                    <h4>Nombre:</h4>
                                    <p>{nombre}</p>
                                    <h4>Email:</h4>
                                    <p>{email}</p>
                                </Col>
                                <Col md={{ span: 6 }} >
                                    <h4>Teléfono:</h4>
                                    <p>{telefono}</p>
                                    <h4>Tienda:</h4>
                                    <p>{tienda}</p>
                                </Col>
                                <Col md={{ span: 12 }} >
                                    <a href={`mailto:${email}`}>Enviar email</a>
                                </Col>
                            </Row>
                        </Container>
                    </section>

            }

        </>
    );
}